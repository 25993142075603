import {
  IconSchool,
  IconCalendarEvent,
  IconArticle,
  IconUsers,
  IconHeartHandshake,
  IconCertificate,
} from "@tabler/icons-react";
import { type RichTextEditorLabels } from "@mantine/tiptap";
import { type ArticleCategory } from "shared/types/article";
import { PATHS } from "./paths";
import { wrapUrlWithCurrentQuery } from "common";

// ----------------------------------------------------------------------

export const socialBrands = ["vk", "instagram", "telegram"] as const;
export const eventLevels = ["based", "advanced"] as const;

export const categoryNames: Record<ArticleCategory, string> = {
  hormonalSystem: "Гормональная система",
  nutriciology: "Нутрициология",
  lfkAndRehabilitation: "ЛФК и реабилитация",
  trainingProcess: "Тренировочный процесс",
  groupDestinations: "Групповые направления",
  psychologyAndSales: "Психология и продажи",
};

export const mainNavigateRoutes = [
  {
    label: "Все обучения",
    linkTo: wrapUrlWithCurrentQuery(PATHS.EXTERNAL.ACADEMY.ALL_DIRECTIONS),
    Icon: IconSchool,
    isExternal: true,
  },
  { label: "Расписание", linkTo: PATHS.SCHEDULE, Icon: IconCalendarEvent },
  { label: "Блог", linkTo: PATHS.BLOG.ROOT, Icon: IconArticle },
  { label: "Преподаватели", linkTo: PATHS.INSTRUCTORS, Icon: IconUsers },
  {
    label: "Партнерская программа",
    linkTo: PATHS.DOCS.AFFILIATE_PROGRAM,
    Icon: IconHeartHandshake,
  },
  {
    label: "Об организации",
    linkTo: PATHS.ABOUT_ORGANIZATION,
    Icon: IconCertificate,
  },
];

export const directionRoutes = [
  // {
  //   label: "Гормональная система",
  //   linkTo: wrapUrlWithCurrentQuery(
  //     PATHS.EXTERNAL.ACADEMY.COURSES.HORMONAL_SYSTEM
  //   ),
  // },
  {
    label: "Нутрициология",
    linkTo: wrapUrlWithCurrentQuery(
      PATHS.EXTERNAL.ACADEMY.COURSES.NUTRICIOLOGY
    ),
  },
  {
    label: "ЛФК и реабилитация",
    linkTo: wrapUrlWithCurrentQuery(
      PATHS.EXTERNAL.ACADEMY.COURSES.LFK_AND_REHABILITATION
    ),
  },
  {
    label: "Тренировочный процесс",
    linkTo: wrapUrlWithCurrentQuery(
      PATHS.EXTERNAL.ACADEMY.COURSES.TRAINING_PROCESS
    ),
  },
  {
    label: "Групповые направления",
    linkTo: wrapUrlWithCurrentQuery(
      PATHS.EXTERNAL.ACADEMY.COURSES.GROUP_DESTINATIONS
    ),
  },
  {
    label: "Психология и продажи",
    linkTo: wrapUrlWithCurrentQuery(
      PATHS.EXTERNAL.ACADEMY.COURSES.PSYCHOLOGY_AND_SALES
    ),
  },
];

export const DEFAULT_LABELS: RichTextEditorLabels = {
  // Controls labels
  linkControlLabel: "Ссылка",
  colorPickerControlLabel: "Цвет текста",
  highlightControlLabel: "Выделить текст",
  colorControlLabel: (color) => `Установить цвет текста ${color}`,
  boldControlLabel: "Жирный",
  italicControlLabel: "Курсив",
  underlineControlLabel: "Подчеркнутый",
  strikeControlLabel: "Зачеркнутый",
  clearFormattingControlLabel: "Очистить форматирование",
  unlinkControlLabel: "Удалить ссылку",
  bulletListControlLabel: "Маркированный список",
  orderedListControlLabel: "Упорядоченный список",
  h1ControlLabel: "Заголовок 1",
  h2ControlLabel: "Заголовок 2",
  h3ControlLabel: "Заголовок 3",
  h4ControlLabel: "Заголовок 4",
  h5ControlLabel: "Заголовок 5",
  h6ControlLabel: "Заголовок 6",
  blockquoteControlLabel: "Цитата",
  alignLeftControlLabel: "Выравнивание текста: слева",
  alignCenterControlLabel: "Выравнивание текста: по-центру",
  alignRightControlLabel: "Выравнивание текста: справа",
  alignJustifyControlLabel: "Выравнивание текста: по ширине",
  codeControlLabel: "Код",
  codeBlockControlLabel: "Блок кода",
  subscriptControlLabel: "Подстрочный индекс",
  superscriptControlLabel: "Надстрочный индекс",
  unsetColorControlLabel: "Сбросить цвет",
  hrControlLabel: "Горизонтальная линия",

  // Link editor
  linkEditorInputLabel: "Введите URL",
  linkEditorInputPlaceholder: "https://example.com/",
  linkEditorExternalLink: "Открыть ссылку в новой вкладке",
  linkEditorInternalLink: "Открыть ссылку в той же вкладке",
  linkEditorSave: "Сохранить",

  // Color picker control
  colorPickerCancel: "Отмена",
  colorPickerClear: "Отменить выбор цвета",
  colorPickerColorPicker: "Выбор цвета",
  colorPickerPalette: "Цветовая палитра",
  colorPickerSave: "Сохранить",
  colorPickerColorLabel: (color) => `Установить цвет текста ${color}`,
};
