import { pythonApi } from "./axios";
import { type AxiosResponse } from "axios";
import { type AmoRequest } from "../types/amo";

// ----------------------------------------------------------------------

export function sendParticipationRequest(
  body: AmoRequest
): Promise<AxiosResponse<undefined, CitadelApiError>> {
  return pythonApi.post("/api/v1/amo/create/leads/", body);
}

export interface CitadelApiError {
  type: string;
  errors: [
    {
      code: string;
      detail: string;
      attr: string;
    }
  ];
}
