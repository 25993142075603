import axios, { type AxiosError } from "axios";
import { API_URL, NEW_API_URL, APP_URL } from "shared/config";

// ----------------------------------------------------------------------

type OriginalRequest =
  | (AxiosError["config"] & {
      _retry?: boolean;
    })
  | undefined;

const createApiClient = (baseURL: string) => {
  const api = axios.create({
    baseURL,
    withCredentials: true,
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      void Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      const originalRequest: OriginalRequest = error.config;

      if (
        error.response?.status === 401 &&
        originalRequest &&
        originalRequest.url === "/auth/refresh"
      ) {
        return Promise.reject(error);
      }

      if (
        error.response?.status === 401 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        const response = await axios.get<{ accessToken: string }>(
          `${baseURL}/auth/refresh`,
          { withCredentials: true }
        );
        const { accessToken } = response.data;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return api.request(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export const api = createApiClient(API_URL);
// export const pythonApi = createApiClient(NEW_API_URL)

export const pythonApi = axios.create({
  baseURL: NEW_API_URL,
  // withCredentials: true,
});
