export function wrapUrlWithCurrentQuery(url: string): string {
  const currentUrl = new URL(window.location.href);
  const currentParams = new URLSearchParams(currentUrl.search);

  const newUrl = new URL(url, window.location.origin);

  currentParams.forEach((value, key) => {
    newUrl.searchParams.append(key, value);
  });

  return newUrl.toString();
}
