import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  createStyles,
  Header as MantineHeader,
  Group,
  Container,
  rem,
  Button,
  Box,
} from "@mantine/core";
import { Logo } from "shared/ui/Logo";
import { ThemeButton } from "features/themeButton";
import { mainNavigateRoutes } from "shared/lib/constants";
import { PATHS } from "shared/lib/paths";
import React from "react";
import { MobileNav } from "./MobileNav";
import { wrapUrlWithCurrentQuery } from "common";

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  inner: {
    height: rem(96),
    display: "flex",
    alignItems: "center",
  },

  logo: {
    marginRight: "auto",
  },

  links: {
    [theme.fn.smallerThan("xl")]: {
      display: "none",
    },
  },

  link: {
    padding: `${rem(10)} ${rem(16)}`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

// ----------------------------------------------------------------------

type Props = {
  simple?: boolean;
};

export const Header = observer(function Header({ simple = false }: Props) {
  const { theme, classes } = useStyles();
  const { pathname } = useLocation();

  return (
    <MantineHeader withBorder={false} height={96}>
      <Container size={1440}>
        <div className={classes.inner}>
          <Logo className={classes.logo} />
          {!simple && (
            <>
              <Group spacing={5} className={classes.links}>
                {mainNavigateRoutes.map(({ label, linkTo, isExternal }) => (
                  <Button
                    className={classes.link}
                    key={linkTo}
                    variant={linkTo === pathname ? "light" : "subtle"}
                    color={linkTo === pathname ? theme.primaryColor : "gray"}
                    radius="xl"
                    size="md"
                    component={Link}
                    to={linkTo}
                    target={isExternal ? "_blank" : undefined}
                    rel={isExternal ? "noopener noreferrer" : undefined}
                  >
                    {label}
                  </Button>
                ))}
                <Button
                  component={Link}
                  to={wrapUrlWithCurrentQuery(PATHS.EXTERNAL.ACADEMY.ROOT)}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="md"
                  radius="xl"
                  variant="gradient"
                >
                  Личный кабинет
                </Button>
                <Box ml="xs">
                  <ThemeButton variant="icon" />
                </Box>
              </Group>

              <MobileNav />
            </>
          )}
        </div>
      </Container>
    </MantineHeader>
  );
});
